<script setup>
    import { Link, usePage } from '@inertiajs/vue3'

    const { props } = usePage()

    const backref = props.backref
    const messagingClick = () => {
        fathom.trackEvent('exn_lander_start_messaging_cta_click')
    }
</script>
<template>
    <div
        class="message-bg relative mx-auto flex min-h-[80vh] w-full max-w-[1252px] flex-col items-center justify-start md:justify-center">
        <div
            class="wrapper sides-lf relative z-[1] mt-[20px] flex h-auto w-full items-center justify-between px-[15px]">
            <img
                src="./../../../../public/images/message/left-side-retina.png"
                class="mt-[15px] h-auto w-10/12 pt-[30px] lg:mt-0 lg:w-[36%]" />

            <div class="flex w-full max-w-full flex-col items-center lg:w-[45%] lg:max-w-[540px]">
                <h1
                    class="mb-[33px] w-auto max-w-full text-center text-[22px] text-white md:text-[36px]">
                    Direct message your favorite stars and creators!
                </h1>

                <img
                    src="./../../../../public/images/message/right-side.png"
                    class="h-auto w-3/4 max-w-[320px] sm:w-1/2 sm:max-w-[500px] md:w-full" />
            </div>
        </div>

        <Link
            :href="route('access', { type: 'join', backref: backref })"
            aria-label="Start Messaging"
            alt="Start Messaging"
            class="relative z-[1] mt-[60px] flex h-[56px] w-[330px] items-center justify-center rounded-[23px] bg-mintgreen text-[29px] font-bold text-[#0a0a0a] hover:bg-hovermintgreen xs:w-[365px] sm:w-[488px] md:mt-[174px] md:h-[62px]"
            @click="messagingClick">
            Start Messaging
        </Link>
    </div>
</template>
<style>
    .message-bg:before {
        content: '';
        background-image: url('../../../../public/images/message/big-bg.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .wrapper {
        flex-direction: column-reverse;
    }

    @media only screen and (max-width: 1024px) {
        .message-bg:before {
            background-image: url('../../../../public/images/message/small-bg.jpg');
        }
    }

    @media only screen and (min-width: 1080px) {
        .wrapper {
            flex-direction: row;
        }
    }
</style>
